// include and initialize the rollbar library with your access token
import Rollbar  from 'rollbar';

const newRollbar = new Rollbar({
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

export default newRollbar;

