import $ from 'jquery';

export default class ApplePayHandler {
  constructor(btn, readyCb) {
    this.applePayButtonClicked = this.applePayButtonClicked.bind(this);
    this.getApplePaySession = this.getApplePaySession.bind(this);
    this.handleOnPaymentAuthorized = this.handleOnPaymentAuthorized.bind(this);
    this.handleOnValidateMerchant = this.handleOnValidateMerchant.bind(this);

    if (btn.length > 0) {
      if (window.ApplePaySession) {
        this.settings = JSON.parse(btn.attr("data-apple-pay"));
        var promise = ApplePaySession.canMakePaymentsWithActiveCard(this.settings.organization.apple_pay_info.merchant_identifier);
        promise.then((canMakePayments) => {
          if (canMakePayments) {
            btn.attr("data-available", true).click(this.applePayButtonClicked);
          }

          readyCb();
        });
    	} else {
        readyCb();
      }
    } else {
      readyCb();
    }
  }

  applePayButtonClicked(e) {
    if (!this.validateForm())
      return;

    var amount;
    var causeName;

    if ($('[data-apple-pay-validatable][name*="amount"]').length > 0)
      amount = parseFloat($('[data-apple-pay-validatable][name*="amount"]').val());
    else if (this.settings.donation)
      amount = this.settings.donation.amount_cents / 100.0;
    else
      amount = 0.01;

    if ($('[data-apple-pay-validatable][name*="cause_id"]').length > 0)
      causeName = $('[data-apple-pay-validatable][name*="cause_id"] option:selected').text();
    else if (this.settings.donation)
      causeName = this.settings.donation.cause.name;

    const paymentRequest = {
  		countryCode: this.settings.organization.country,
  		currencyCode: this.settings.organization.preferred_currency_code,
  		total: {
  			label: this.settings.organization.name,
        type: 'final',
  			amount: amount
  		},
  		supportedNetworks:[ 'amex', 'discover', 'masterCard', 'visa'],
  		merchantCapabilities: [ 'supports3DS', 'supportsCredit','supportsDebit' ],
  		requiredBillingContactFields: [ 'postalAddress', 'name' ],
      requiredShippingContactFields: [ 'email' ]
  	};

    if (amount === 0.01) {
      paymentRequest.total.label = `${paymentRequest.total.label} (UPDATE CARD)`;
    } else {
      paymentRequest.lineItems = [{
        type: "final",
        label: causeName,
        amount: amount
      }];
    }

  	this.session = new ApplePaySession(2, paymentRequest);

  	/**
  	* Merchant Validation
  	* We call our merchant session endpoint, passing the URL to use
  	*/
  	this.session.onvalidatemerchant = this.handleOnValidateMerchant;

  	/**
  	* Payment Authorization
  	* Here you receive the encrypted payment data. You would then send it
  	* on to your payment provider for processing, and return an appropriate
  	* status in session.completePayment()
  	*/
  	this.session.onpaymentauthorized = this.handleOnPaymentAuthorized;

  	// All our handlers are setup - start the Apple Pay payment
  	this.session.begin();
  }

  createApplePayPaymentMethod(payment) {
    return new Promise(function (resolve, reject) {
      $.post('/apple_pay_payment_methods', payment, function(data) {
        resolve(data);
      }).fail(function(jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      });
    });
  }

  getApplePaySession(url) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `/organizations/${this.settings.organization.token}/apple_pay_sessions`,
        data: { url: url },
        success: function(data) {
          resolve(data);
        }
      }).fail(function(jqXHR, textStatus, errorThrown) {
        reject(errorThrown);
      });
    });
  }

  handleOnValidateMerchant(event) {
		const validationURL = event.validationURL;
		return this.getApplePaySession(event.validationURL).then((response) => {
			this.session.completeMerchantValidation(response);
		}).catch((reason) => {
      this.session.abort();
      alert(reason);
    });
  }

  handleOnPaymentAuthorized(event) {
    // Send payment for processing...
    const payment = event.payment;
    return this.createApplePayPaymentMethod(payment).then((data) => {
      this.populateForm(payment, data);
      this.session.completePayment(ApplePaySession.STATUS_SUCCESS);
      submitForm();
    }).catch((reason) => {
      this.session.abort();
      alert(reason);
    });
  }

  populateForm(payment, data) {
    var form = $("#card_number").closest("form");
    form.find(".payment-method-first-name").val(payment.billingContact.givenName);
    form.find(".payment-method-last-name").val(payment.billingContact.familyName);

    if (form.find(".payment-method-address-1").length > 0)
      form.find(".payment-method-address-1").val(payment.billingContact.addressLines[0]);

    if (form.find(".payment-method-city").length > 0)
      form.find(".payment-method-city").val(payment.billingContact.locality);

    if (form.find(".payment-method-state-province").length > 0)
      form.find(".payment-method-state-province").val(payment.billingContact.administrativeArea);

    if (form.find(".payment-method-postal-code").length > 0)
      form.find(".payment-method-postal-code").val(payment.billingContact.postalCode);

    if (form.find(".payment-method-country").length > 0)
      form.find(".payment-method-country").val(payment.billingContact.countryCode.toUpperCase());

    form.find(".payment-method-email-address").val(payment.shippingContact.emailAddress);
    form.find("#payment_method_token").val(data.payment_method.token);

    form.get(0).submit();
  }

  validateForm() {
    let valid = true;
    $('[data-apple-pay-validatable]').toArray().every((field) => {
      if ($(field).val().trim() === '') {
        alert($(field).attr('data-apple-pay-validatable'));
        return valid = false;
      } else {
        return true;
      }
    });

    return valid;
  }
}
