import * as bootstrap from 'bootstrap';
import * as FieldKit from 'field-kit';
// import $ from 'jquery';
import { createPopper } from '@popperjs/core';
import ColorPicker from 'simple-color-picker';
import * as Ladda from 'ladda';
import _ from 'lodash';

// const colorPicker = new ColorPicker();

window.handleLoginButtonReCaptcha = (response) => {
  let l = Ladda.create($('button.ladda-button.g-recaptcha')[0]);
  l.start();

  $('[id$=g_recaptcha_response]').val(response);

  setTimeout(() => {
    $('[id$=g_recaptcha_response]').closest('form')[0].submit();
  }, 100);
};

$(function () {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
  $('[data-toggle="popover"]').popover();
  Ladda.bind('button[type=submit]:not(.g-recaptcha)');

  // Disable all Ladda buttons by default when clicked. It will be the
  // responsibility of the click handlers to re-enable them.
  //
  $(".ladda-button:not(.g-recaptcha)").on('click', function (e) {
    $(e).attr('disabled', 'disabled');
  });

  _.each($("[data-phone-number]"), function(el) {
    new FieldKit.TextField(el, new FieldKit.PhoneFormatter());
  });
});
