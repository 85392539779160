export default function paramsToObject() {
  var obj = _.object(_.map(location.search.substring(1).split("&"), (pair) => {
    return pair.split("=");
  }));

  return _.omit(obj, arguments);
}

export function reloadUrlOnSelectChange(selectName) {
  $(`[name='${selectName}']`).on("change", function(e) {
    var params = paramsToObject(selectName);
    var queryString = [`${$(e.target).prop("name")}=${$(e.target).val()}`];

    _.each(Object.keys(params), (key) => {
      queryString = queryString.concat(`${key}=${params[key]}`);
    });

    location.href = `${location.href.split("?")[0]}?${queryString.join("&")}`;
  });
}

export function t(name) {
  var value = null;
  $(".i18n").each(function(_index, el) {
    var data = $(el).data(name.replace(/[._]/g, '-'));
    if (null != data) {
      return value = data;
    }
  });
  return value;
}
