import PaypalButton from './paypal_button';
import  { t as translate } from './utility.js';

export default class PaypalExpressCheckoutOnlineDonationButton extends PaypalButton {
  constructor(buttonDivId) {
    super();
    this.params = $(buttonDivId).data();
      paypal.Button.render({
        env: this.params["environment"], // sandbox | production
        onAuthorize: this.onAuthorize.bind(this),
        onError: this.onError.bind(this),
        onCancel: this.onCancel.bind(this),
        payment: this.payment.bind(this),
        style: {
          label: 'pay',
          tagline: false,
          shape: 'rect',
          size: 'medium'
        }

      }, buttonDivId);
    this.disableEnterToSubmit();
  }

  disableEnterToSubmit() {
    $('#new_donation').on('keyup keypress', (e) => {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        return false;
      }
    });
  }

  onAuthorize(data, actions) {
    // #create will call #purchase which will execute the payment using the payment id stored
    // on the donor

    this.disablePayPalButton();
    $('#donation_ppec_payment_id').val(data['paymentID']);
    $('#new_donation')[0].submit();
  }

  onError(err) {
    Rollbar.error(err);
    var self = this;
      if ($("#new_donation").valid()) {
        this.displayFlashMessage(translate('errors.messages.something_went_wrong'), 'danger');
      }
  }

  // Wait for the PayPal button to be clicked
  payment(resolve, reject) {
    var self = this;
    var form = $("#new_donation");
      form.validate({
        highlight: function(element, errorClass) {
          var errorParent = $(element).parent();
            if (!errorParent.hasClass("has-error")) {
              errorParent.addClass("has-error");
              var msg = translate('paypal_express_checkout_online_donations.new.errors.required');
              var el =
                jQuery.parseHTML('<span class="help-block">' + msg + '</span>');
              $(el).insertAfter(errorParent);
            }
        },

        errorPlacement: function(error, element) {
          // We just want to block the default behavior here
        },

        invalidHandler: function(event, validator) {
          // Let the paypal widget know there's been a problem.

          return false;
        },
        submitHandler: function() {
          // We don't want anything to happen here.
        }
      });
    if (form.valid()) {
      // Clear validation error messages

      $(".has-error").removeClass("has-error")
      $("#new_donation span.help-block").remove()

      // Get csrf token

      var csrf_token = $( 'meta[name="csrf-token"]' ).attr( 'content' );

      var data = {}

      // Set value of donation entered in amount text field

      var amount = $('#donation_amount').val();
      data['donation_amount'] = amount;

      // Make a call to the txt2give server to set up the payment

      return paypal.request.post(this.params["createUrl"], data, { headers: {'X-CSRF-Token': csrf_token } } ).then((res) => {
        return res.payToken;
      }).catch((e) => {
        reject(
          new Error("Error occurred while contacting server to create payment: " + e.message)
        );
      });
    }
    else {
      return null;
    }
  }
}
