import newRollbar from './rollbar';
import * as Ladda from 'ladda';
import { gatherDonorBankAccount, onRegistrationNewPage, displayFeedback } from './donor_info';

window.addEventListener('DOMContentLoaded', _e => {
  if ($("#bank_account_fields").length < 1) { return; }

  $('#bankselectlnk').click((_e) => {
    plaidPopUp().open();
  });
});

const plaidPopUp = () => {
  let plaid = Plaid.create({
    selectAccount: true,
    env: Txt2Give.settings.plaid.env,
    apiVersion: 'v2',
    clientName: Txt2Give.settings.organization.name,
    key: Txt2Give.settings.plaid.public_key,
    product: ['auth'],
    onSuccess: (publicToken, metadata) => {
      // console.log("#########   metadata >>>> ", JSON.stringify(metadata));
      const data = { donor: {
        plaid_token: publicToken,
        plaid_account_id: metadata.account_id
      }};
      let form = $('#bank_account_fields').closest('form');
      return $.ajax({
        type: 'POST',
        url: '/api/v2/plaid-verifications',
        dataType: 'json',
        data: data,
        success: (data) => {
          // console.log("###  SUCCESS AFTER plaid-verifications  >>>> ", JSON.stringify(data));
          let account_number_last_4;
          if (data.success === true) {
            form.attr('data-bank-account-number', data.account_number);
            form.attr('data-bank-account-routing-number', data.routing_number);
            form.attr('data-bank-name', data.bank_name);
            form.attr('data-bank-account-type', data.type);

            account_number_last_4 = data.account_number.substr(data.account_number.length - 4);

            form.find("#selected_bank_account #bank_account_name").text(data.bank_name + " " + data.type + " (" + account_number_last_4 + ")");
            $(".btn-bank-account-select").hide();
            createSpreedlyToken();
            return $(".btn-bank-account-change").show();
          } else {
            alert('Your bank account info could not be verified.  Please make sure the info is correct.');
            return Ladda.stopAll();
          }
        }
      }).fail((request, status, error) => {
        form.find("input[type='submit'], button").removeAttr('disabled');
        Ladda.stopAll();
        newRollbar.error('Error while verifying bank account info');
        return alert('Something went wrong.  We have been notified.');
      });
    },
    onExit: (err, metadata) => {
      if (err != null) {
        newRollbar.error('Plaid error', err);
      }
      newRollbar.info('Plaid metadata', metadata);
      return Ladda.stopAll();
    }
  });

  return plaid;
};

const createSpreedlyToken = () => {
  const Url = `https://core.spreedly.com/v1/payment_methods.json?environment_key=${Txt2Give.settings.spreedly.environment_key}`;
  const data = gatherDonorBankAccount(true);
  // console.log("#########  77 DATA >>>> ", JSON.stringify(data));
  $.ajax({
      type: 'POST',
      url: Url,
      dataType: 'json',
      data,
      success: (data) => {
        // console.log("#########   83 createSpreedlyToken >>>> ", JSON.stringify(data));
        $('#payment_method_token').val(data.transaction.payment_method.token);
        return true;
      }
    }).fail((request, _status, _error) => {
      newRollbar.error(_.reduce(request.responseJSON.errors, (memo, e) => {
        return memo + ' ' + e.message;
      }, ''));
      return alert('Something went wrong. We have been notified.');
    });
};

const finalStepBankAccount = () => {

  let url, fullData;

  let donorInfo = gatherDonorBankAccount(false);
  // console.log("#########  101 donorInfo >>>> ", JSON.stringify(donorInfo));

  if (document.getElementById('account_authorization')) { // bank payment, registration screen
    let token = $('#payment_method_token').val();
    let account_authorization = $('#account_authorization').val() == 1 ? true : false;
   donorInfo['bank_accounts_attributes'] = [{ account_authorization, token }];
  }

  const organization_token = $('#organization-info').data().organizationToken;

  const amount = $('#donation_amount').val();
  const frequency = $('#donation_frequency').val();
  const donor_token = $('#donor-info').data().donorToken;

  if (onRegistrationNewPage()) {
    url = `/api/v2/donations`;

    fullData = { donation: { amount,
                             cause_id: donorInfo.cause_id,
                             location_id: donorInfo.location_id,
                             frequency,
                             donor_id: donor_token,
                             organization_id: organization_token,
                             donor_attributes: donorInfo
                           }};
  } else {
    url = `/organizations/${organization_token}/donors/${donor_token}/registrations`;

    fullData = { location_id: donorInfo.location_id, cause_id: donorInfo.cause_id };
    delete donorInfo.location_id;
    delete donorInfo.cause_id;

    fullData['donor'] = donorInfo;
  }
  // console.log("#### 130 BANK fullData BEFORE final step >>>> ", JSON.stringify(fullData));
  $.ajax({
    type: 'POST',
    url,
    beforeSend: (xhr) => {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(fullData)
  }).done((data) => {
    // console.log("#########  133 T2G SUCCESS >>>> ", JSON.stringify(data));
    Ladda.stopAll();
    return displayFeedback(data);
  }).fail((request, _status, error) => {
    return alert('Something went wrong. We have been notified.');
    Ladda.stopAll();
    newRollbar.error('T2G API call', error);
  });
};

export { finalStepBankAccount };
