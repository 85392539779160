
import Pickr from '@simonwep/pickr';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('color_picker')) {
    loadPickr();
    const subBtn = document.getElementById('savebutton');
    subBtn.addEventListener('click', saveInfo);
  }
}, false);

const loadPickr = () => {
  let newBgColor, newColor;
  const bGinput = document.getElementById('organization_preferred_header_background_color');
  const coloRinput = document.getElementById('organization_preferred_header_color');
  const orgTitle = document.getElementById('org_name');
  const orgContact_info = document.getElementById('public_facing_contact_info');
  const navbarFixedTop = document.getElementById('ognavbartop');

  const headerBgPickr = Pickr.create({
    el: '#first-color-picker',
    theme: 'classic', // or 'monolith', or 'nano'
    default: bGinput.dataset.originalColor,
    swatches: [
        'rgba(244, 67, 54, 1)',
        'rgba(233, 30, 99, 0.95)',
        'rgba(156, 39, 176, 0.9)',
        'rgba(103, 58, 183, 0.85)',
        'rgba(63, 81, 181, 0.8)',
        'rgba(255, 193, 7, 1)'
    ],
    components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,
        // Input / output Options
        interaction: {
            hex: true,
            rgba: true,
            input: true,
            clear: true,
            save: true
        }
    }
  });

  headerBgPickr.on('change', (color, instance) => {
    newBgColor = color.toHEXA().toString();
    bGinput.value = newBgColor;
    console.log('Event: "Change" color >>', color, ' toHEXA >>', newBgColor, '  instance >> ', instance);
    return navbarFixedTop.style.backgroundColor = newBgColor;
  }).on('save', instance => {
    console.log('Event: "save"', instance);
    return navbarFixedTop.style.backgroundColor = color.toHEXA().toString();
  });

  const headerColorPickr = Pickr.create({
    el: '#second-color-picker',
    theme: 'classic', // or 'monolith', or 'nano'
    default: coloRinput.dataset.originalColor,
    swatches: [
        'rgba(244, 67, 54, 1)',
        'rgba(233, 30, 99, 0.95)',
        'rgba(156, 39, 176, 0.9)',
        'rgba(103, 58, 183, 0.85)',
        'rgba(63, 81, 181, 0.8)',
        'rgba(255, 193, 7, 1)'
    ],
    components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,
        // Input / output Options
        interaction: {
            hex: true,
            rgba: true,
            input: true,
            clear: true,
            save: true
        }
    }
  });

  headerColorPickr.on('change', (color, instance) => {
    newColor = color.toHEXA().toString();
    console.log('Event: "Change HD" color >>', color, ' toHEXA >>', newColor, '  instance >> ', instance);
    coloRinput.value = newColor;
    orgContact_info && (orgContact_info.style.color = newColor);
    return orgTitle.style.color = newColor;
  }).on('save', instance => {
    console.log('Event: "save"', instance);
    return orgTitle.color = color.toHEXA().toString();
  });
};

const saveInfo = () => {
  let feedback = $('#feedback');

  const organization_id = document.getElementById('organization_organization_id').value;
  const header_bg_color = document.getElementById('organization_preferred_header_background_color').value;
  const header_color = document.getElementById('organization_preferred_header_color').value;

  const data = { organization: { organization_id, header_color, header_bg_color }  };
  console.log("#########  ** SUBMIT DATA var  ** :  >>>> ", data);
  const API_URL = `/api/v2/organizations/${organization_id}`;
  fetch(API_URL, {
    method: 'PATCH',
    headers: { 'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${organization_id}` },
    body: JSON.stringify(data)
  })
    .then((response) => response.json())
    .then((result) => {
      console.log('FETCH Result >>> ', result);
      let msg = '';
      if (result.error == true) {
        msg = '<span class="alert alert-danger">Something went wrong</span>';
      } else {
        msg = '<span class="alert alert-success">Data succesfully saved</span>';
        // Close the tab after a short delay
        setTimeout(() => window.close(), 2000); // 2 seconds delay
      }

      feedback.html(msg);
   })
    .catch((error) => {
      console.error('Promise Error:', error);
    });
};
