// Collect info for a new registration or a new donations HTML form.
// TODO: Change this implementation so that all fields and configs are equal
// and the only difference is if onRegistrationNewPage() returns true

const gatherDonorInfo = (spreedlyFormat, justValues=false) => {
  if (onRegistrationNewPage() || onUpdateDonorPage()) {
    return gatherDonorRegistration(spreedlyFormat, justValues);
  } else {
    return gatherDonorDonation(spreedlyFormat, justValues);
  }
};

// Current  PATH: /r/:organization_id DONOR FORM
const gatherDonorRegistration = (spreedlyFormat, justValues) => { // On RegistrationController
  let donorInfo;
  const first_name = $('#donor_first_name');
  const last_name = $('#donor_last_name');
  const email_address = $('#donor_email_address');
  const postal_code = $('#donor_postal_code');
  const street_address_1 = $('#donor_street_address_1');
  const city = $('#donor_city');
  const state_province = $('#donor_state_province');
  const country = $('#donor_country');
  const location_id = $('#donor_location_id');
  const cause_id = $('#donor_cause_id');
  const donation_reference_number = $('#donation_reference_number');

  if (spreedlyFormat) {
    const monthAndYear = ccExpDate();
    donorInfo = {
      first_name: first_name.val(),
      last_name: last_name.val(),
      month: monthAndYear[0],
      year: `20${monthAndYear[1]}`,
      email: email_address.val(),
      zip: postal_code.val(),
      metadata: {
        address1: street_address_1.val(),
        state: state_province.val(),
        city: city.val(),
        country: country.val()
      }
    };
  } else {
     if (justValues) {
       return { donor: { first_name: first_name.val(),
                         last_name: last_name.val(),
                         email_address: email_address.val(),
                         postal_code: postal_code.val(),
                         street_address_1: street_address_1.val(),
                         city: city.val(),
                         state_province: state_province.val(),
                         country: country.val() },
                location_id: location_id.val(),
                cause_id: cause_id.val(),
                donation_reference_number: donation_reference_number.val()
       };
     }

    donorInfo = { first_name, last_name, email_address, postal_code, street_address_1,
                  city, state_province, country, donation_reference_number, location_id, cause_id };

  }
  return donorInfo;
};

// CURRENT PATH: /organizations/:token_id/donations/new?locale=en DONATION FORM
const gatherDonorDonation = (spreedlyFormat, justValues) => {
  let donorInfo;

  let first_name = $('#donation_donor_attributes_first_name');
  let last_name = $('#donation_donor_attributes_last_name');
  let email_address = $('#donation_donor_attributes_email_address');
  let postal_code = $('#donation_donor_attributes_postal_code');
  let street_address_1 = $('#donation_donor_attributes_street_address_1');
  let city = $('#donation_donor_attributes_city');
  let state_province = $('#donation_donor_attributes_state_province');
  let country = $('#donation_donor_attributes_country');
  let location_id = $('#donation_location_id');
  let cause_id = $('#donation_cause_id');
  let donation_amount = $('#donation_amount');
  let donation_reference_number = $('#donation_reference_number');

  if (spreedlyFormat) {
    const monthAndYear = ccExpDate();
    donorInfo = {
      first_name: first_name.val(),
      last_name: last_name.val(),
      month: monthAndYear[0],
      year: `20${monthAndYear[1]}`,
      email: email_address.val(),
      zip: postal_code.val(),
      metadata: {
        address1: street_address_1.val(),
        state: state_province.val(),
        city: city.val(),
        country: country.val()
      }
    };
  } else {
    if (justValues) {
      return { donation: {
                donor_attributes: { first_name: first_name.val(),
                                    last_name: last_name.val(),
                                    email_address: email_address.val(),
                                    postal_code: postal_code.val(),
                                    street_address_1: street_address_1.val(),
                                    city: city.val(),
                                    state_province: state_province.val(),
                                    country: country.val()
                                  },
                amount: donation_amount.val(),
                location_id: location_id.val(),
                cause_id: cause_id.val(),
                donation_reference_number: donation_reference_number.val(),
        },
        cause_id: cause_id.val(),
      };
    }

    donorInfo = { first_name, last_name, email_address, postal_code, street_address_1,
                  city, state_province, country, location_id, donation_amount, cause_id,
                  donation_reference_number};

  }

  return donorInfo;
};

const ccExpDate = () => {
  let exp;
  if (!document.getElementById('cc-exp')) {
    exp = '07/27';
  } else {
    exp = $('#cc-exp').val();
  }

  let monthAndYear = exp.split('/').map(k => k.trim());
  return monthAndYear;
};

const gatherDonorBankAccount = (bank_format) => {
  let donorInfo;
  let formValues = justFormValuesOnBankOption();

  const form = $('#bank_account_fields').closest('form');

  if (bank_format) {
    donorInfo = {
      payment_method: {
        bank_account: {
          first_name: formValues.first_name,
          last_name: formValues.last_name,
          bank_name: form.data('bank-name'),
          bank_routing_number: form.data('bank-account-routing-number'),
          bank_account_number: form.data('bank-account-number'),
          bank_account_type: form.data('bank-account-type'),
          bank_account_holder_type: $('#bank_account_holder_type').val(),
      },
      email: formValues.email_address,
      data: {
        address1: formValues.street_address_1,
        city: formValues.city,
        state: formValues.state_province,
        zip: formValues.postal_code,
        country: formValues.country
      }
    }
  };
  } else {
    donorInfo = { first_name: formValues.first_name,
                  last_name: formValues.last_name,
                  email_address: formValues.email_address,
                  postal_code: formValues.postal_code,
                  street_address_1: formValues.street_address_1,
                  city: formValues.city,
                  state_province: formValues.state_province,
                  country: formValues.country,
                  location_id: formValues.location_id,
                  cause_id:  formValues.cause_id
                };
  }
  return donorInfo;
};

const justFormValuesOnBankOption = () => {
  let formName, locationField;

  if (onRegistrationNewPage()) {
    locationField = 'donor';
    formName = 'donor';
  } else {
    locationField = 'donation';
    formName = 'donation_donor_attributes';
  }

  return { first_name: $(`#${formName}_first_name`).val(),
           last_name: $(`#${formName}_last_name`).val(),
           email_address: $(`#${formName}_email_address`).val(),
           street_address_1: $(`#${formName}_street_address_1`).val(),
           postal_code: $(`#${formName}_postal_code`).val(),
           city: $(`#${formName}_city`).val(),
           state_province: $(`#${formName}_state_province`).val(),
           country: $(`#${formName}_country`).val(),
           location_id: $(`#${locationField}_location_id`).val(),
           cause_id: $(`#${locationField}_cause_id`).val()
         };
};

const clearGeoLocationFields = () => {
  if (onRegistrationNewPage()) {
    $('#donor_city').val('');
    $('#donor_state_province').val('');
    $('#donor_country').val('');
  } else {
    $('#donation_donor_attributes_city').val('');
    $('#donation_donor_attributes_state_province').val('');
    $('#donation_donor_attributes_country').val('');
  }
  return true;
};

// #textDonation only exists on registration page: /r/{organizationToken}
const onRegistrationNewPage = () => {
  if ($('#textDonation').length){return true;}

  return false;
};

// On /organizations/:organization_id/donations/new. New Donor: not registered yet.
const onOnlineGivingNewPage = () => {
  if ($('#donation_donor_attributes_first_name').length){return true;}

  return false;
};

const onUpdateDonorPage = () => {
  return window.location.href.indexOf('donor/edit') > -1 || window.location.href.indexOf('/u/') > -1;
};

// Donor already registered
const onNewDonationRegisteredPage = () => {
  if ($('#donor_already_registered').length){return true;}

  return false;
};

const displayFeedback = (messages) => {

  if (!messages){
    messages = [{text: 'Thank you!', error: false}];
  }

  if (messages.constructor.name == 'Object') {
    messages = [messages];
  }

  let cssClass, msgs, finalMessage;

  const errorExist = messages.find(o => o.error == true);

  if (messages) {
    msgs = messages.map(msg => {
      cssClass = msg.error ? 'error_feedback' : 'ok_feedback';
      return `<li class="${cssClass}">${msg.text}</li>`;
    });
  }

  if (!errorExist) { // no error
    $('#new_donation').hide();
    $('#payment_method_form').hide();
    $('#update_donor').hide();
  }

  finalMessage = msgs.toString();
  // console.log("  ############  ** FInal MESAGE ** :  >>>> ", JSON.stringify(finalMessage));
  const richList = `<ul class="cc_messages">${finalMessage}</ul>`;
  $('#feedback').html(richList);
  $('#feedback').fadeIn('slow');
  return;
};

export { gatherDonorInfo, gatherDonorBankAccount, clearGeoLocationFields, onRegistrationNewPage,
         onUpdateDonorPage, onOnlineGivingNewPage, onNewDonationRegisteredPage, displayFeedback };
