const tinycolor = require("tinycolor2");

(function() {
  window.setHeaderColor = function() {
    let orgId = document.getElementById('org_name_container');
    let hex =  orgId.getAttribute('data-color');
    let color = tinycolor(hex);

    return $(".navbar-fixed-top").css("border-bottom", "1px solid " + (color.setAlpha(.15).toRgbString()));
  };
}).call(this);

