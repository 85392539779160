const util = require('./utility');
import newRollbar from './rollbar';
import * as Ladda from 'ladda';
import { gatherDonorInfo, onRegistrationNewPage, onUpdateDonorPage, displayFeedback } from './donor_info';

window.addEventListener('DOMContentLoaded', _e => {
  if (!document.getElementById('addressfields')) { return; } // No Donation or Registration screen

  $(window).resize(setSpreedlyStyles);
});

const setupSpreedly = () => {
  if (!document.getElementById('card_number')) { return; }

  const organizationData = document.getElementById('organization-info').dataset;
  Spreedly.init(organizationData.spreedlyEnvironmentKey, {
    'card_number': 'spreedly-number',
    'cvc_code': 'spreedly-cvv'
  });

  // A 'ready’ event is triggered when the iFrames have been successfully initialized.
  Spreedly.on('ready', () => {
    setSpreedlyStyles();
    return;
  });

  // Trigger after Spreedly.tokenizeCreditCard
  // Receiving the tokenized payment method
  // When a card has been tokenized by Spreedly, an event is fired that includes
  // the generated payment method token
  Spreedly.on('paymentMethod', (token, pmData) => checkCardType(pmData.first_six_digits)
          .then(() => {
            // console.log("## 30 Spreedly PaymentMethod TOKEN >>> ", token, ' pmData >> ', JSON.stringify(pmData));
            return submitFormAjax(token);
          }, (errorMessage) => {
            Ladda.stopAll();
            return displayFeedback(errorMessage);
          }));

  // 'validation', Triggered when validation of the iFrame is requested.
  // This event will only fire after validate() has been called.
  Spreedly.on('validation', (inputProperties) => {
    // console.log('######## Spreedly VALIDATION  ** inputProperties ** :  >>>> ', JSON.stringify(inputProperties));
    if (!inputProperties['validNumber']) {
      Ladda.stopAll();
      displayFeedback({text: 'Please enter a valid credit card number',
                       error: true});
      return false;
    }
    let exp = $('#cc-exp').val();

    if (exp.length < 3) {
      Ladda.stopAll();
      displayFeedback({text: util.t('javascript.credit_cards.alerts.invalid_card_exp'),
                       error: true});
      return false;
    }
    if (!inputProperties['validCvv']) {
      Ladda.stopAll();
      displayFeedback({text: util.t('javascript.credit_cards.alerts.invalid_card_cvc'),
                       error: true});
      return false;
    }

    if (inputProperties['validNumber'] && inputProperties['validCvv']) {
      const donorInfo = gatherDonorInfo(true);
      // console.log("#########   74 donorInfo >>>> ", JSON.stringify(donorInfo));
      return Spreedly.tokenizeCreditCard(donorInfo);
    } else {
      let messages = [];
      if (inputProperties['numberLength'] == 0) {
        messages.push({ text: util.t('javascript.credit_cards.alerts.card_number_required'),
                        error: true });
      } else if (inputProperties['validNumber'] == false) {
        messages.push({ text: util.t('javascript.credit_cards.alerts.invalid_card_number'),
                        error: true });
      } else if (inputProperties['cvvLength'] == 0) {
        messages.push({ text: util.t('javascript.credit_cards.alerts.card_cvc_required'),
                        error: true });
      } else if (inputProperties['validCvv'] == false) {
        messages.push({ text: util.t('javascript.credit_cards.alerts.invalid_card_cvc'),
                        error: true });
      } else {
        newRollbar.error('Unknown error while validating payment info', inputProperties);
        messages.push({ text: util.t('javascript.credit_cards.alerts.payment_method_validation_error'),
                        error: true });
      }
      // console.log("#########   VALUE messages >>>> ", messages);
      Ladda.stopAll();

      displayFeedback(messages);
      return false;
    }
  });

  Spreedly.on('errors', (errors) => {
    // console.log("#### 94 Spreedly.on(errors) pmData >>>>> ", JSON.stringify(errors));

    if (typeof console !== 'undefined' && console !== null) {
      // console.log(errors);
    }

    const handleErrors  = ['invalid', 'valid', 'blank', 'blank_card_type', 'expired'];

    const alertMessages = errors.map(error => {
      // console.log("####  ** 107 ERROR ** :  >>>> ", JSON.stringify(error));
      const key = error.key.split('.')[1];
      if (handleErrors.some(errorKey => errorKey === key)) {
        return { text: error.message, error: true };
      }
    });
    if (alertMessages.length == 0) return;

    Ladda.stopAll();
    if (alertMessages.length > 0) {
      // console.log("####  ** 103 alertMessages ** :  >>>> ", JSON.stringify(alertMessages));
      return displayFeedback(alertMessages);
    } else {

      newRollbar.error('Error tokenizing payment info', errors);
      let message = { text: util.t('javascript.credit_cards.alerts.something_went_wrong'),
                      error: true };
      return displayFeedback(message);
    }
  });
};

const isTestCard = iin => ['411111', '555555', '378282', '601111'].includes(iin);

// Credit Card / Debit Card Number Checker tool is designed to check the validity
// of Credit Card / Debit Card Number and check the (BIN) base on updated database.
const checkCardType = (iin) => {
  // console.log("#########   131 IIN >>>> ", JSON.stringify(iin));
  const deferred = new $.Deferred();

  if (Txt2Give.settings.organization.restrict_to_debit != true) {
    return deferred.resolve();
  }

  if (isTestCard(iin) == true) {
    // console.log("#### 145 IS TEST !!! >>> " );
    return deferred.resolve();
  }

  $.get(`${Txt2Give.settings.bin_codes.api_url_base}`,
        {
          'format': 'json',
          'api_key': Txt2Give.settings.bin_codes.api_key,
          'bin': iin
        },
        { dataType: 'json' })
    .done((data) => {
      if (data.type == 'DEBIT') {
        return deferred.resolve();
      }

      newRollbar.info('Card submitted not a debit card', {
        brand: data.card,
        country_code: data.countrycode,
        bank: data.bank,
        card_type: data.card
      });

      return deferred.reject(util.t('javascript.credit_cards.alerts.not_debit_card'));
    }).fail((_response, errorText) => {
        newRollbar.error(`Error while communicating with Neutrino: ${errorText}`);
        return deferred.reject(util.t('javascript.credit_cards.alerts.card_type_unknown'));
      });

  return deferred;
};

const submitFormAjax = (spreedly_token) => {
  let donorInfo = gatherDonorInfo(false, true);

  // console.log("#########   VALUE >>>> ", JSON.stringify(donorInfo));

  if (donorInfo.donation) {  // add the spreedly_token
   donorInfo.donation.donor_attributes.credit_cards_attributes = [{ token: spreedly_token }];
  } else {
   donorInfo.donor.credit_cards_attributes = [{ token: spreedly_token }];
  }

  const organization_token = $('#organization-info').data().organizationToken;
  const donor_token = $('#donor-info').data().donorToken;

  let url, fullData, http_method;
  if (onRegistrationNewPage()) {
    url = `/organizations/${organization_token}/donors/${donor_token}/registrations`;
    http_method = 'POST';

    fullData = donorInfo;
    fullData.donor['spreedly_token'] = spreedly_token;
    fullData.donor['donor_id'] = donor_token;
    fullData.donor['organization_id'] = organization_token;
  } else if (onUpdateDonorPage()) {
    // console.log("#########   spreedly_tokenVALUE >>>> ", spreedly_token );
    url = `/organizations/${organization_token}/donors/${donor_token}/payment_method`;
    http_method = 'PUT';
    fullData = donorInfo;
    fullData['donor_id']        = donor_token;
    fullData['organization_id'] = organization_token;
  } else {
    http_method = 'POST';
    url = '/api/v2/donations';
    const frequency = $('#donation_frequency').val() || 'once';

    fullData = donorInfo;
    fullData.donation['frequency'] = frequency;
    fullData.donation['spreedly_token'] = spreedly_token;
    fullData.donation['donor_id'] = donor_token;
    fullData.donation['organization_id'] = organization_token;
  }

  $.ajax({
    url,
    beforeSend: (xhr) => {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    method: http_method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    data: JSON.stringify(fullData),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    timeout: 15000
  }).done((data) => {
    Ladda.stopAll();
    return displayFeedback(data);
  }).fail((jqXHR, textStatus) => {
    Ladda.stopAll();
    let message;
    if (textStatus === 'timeout') {
        message = { text: util.t('javascript.credit_cards.alerts.timeout'), error: true };
    } else {
        message = { text: util.t('javascript.credit_cards.alerts.something_went_wrong'), error: true };
    }
    newRollbar.error('Error while submitting payment info', {'Error status': textStatus, 'Transaction data': fullData});
    return displayFeedback(message);
  });
};

const setSpreedlyStyles = () => {
  if (!document.getElementById('card_number')) { return; } // there is no card form

  // Match the style of the Bootstrap form fields as closely as possible
  const style = `display: block; width: ${$("#cc-exp").width()}px; padding: 8px 12px; font-size: 15px; line-height: 1.4; color: #6f6f6f; background-color: white; background-image: none; border: 1px solid #cccccc; border-radius: 0; -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s; -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s`;
     // To use the prettyFormat, we have to set the field type to "text"
  Spreedly.setFieldType('text');
  Spreedly.setNumberFormat('prettyFormat');
  Spreedly.setStyle('cvv', style);
  Spreedly.setStyle('number', style);
  return;
};

export { setupSpreedly };

