import { TempusDominus } from '@eonasdan/tempus-dominus';

if (document.getElementsByClassName('recurrence-datetimepicker')) {
  // new TempusDominus(document.getElementById('recurrdatetimepicker'), {
    //put your config here
  // });
}

// $(function() {

//   $('.recurrence-datetimepicker').datetimepicker({
//     format: 'n/j/Y',
//     timepicker: false,
//     minDate: 0,
//     onGenerate: function(){
//       $(".xdsoft_disabled").removeClass("xdsoft_disabled");
//     }
//   });

//   if (frequencyIsBimonthly()) {
//     $('.next_recurrence_at_container').hide();
//   }
//   $('#donation_recurrence_frequency').change(function(){
//     if (frequencyIsBimonthly()) {
//       $('.next_recurrence_at_container').hide();
//     } else {
//       $('.next_recurrence_at_container').show();
//     }
//   });


//   function frequencyIsBimonthly() {
//     return ("bimonthly" === $('#donation_recurrence_frequency').val());
//   }
// });
