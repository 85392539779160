import $ from 'jquery';
import PaypalExpressCheckoutOnlineDonationButton from './paypal_express_checkout_online_donation_button';
import PaypalExpressCheckoutButton from './paypal_express_checkout_button';

$(function() {
  if ($("#paypal-button-online-donation-container").length > 0) {
    new PaypalExpressCheckoutOnlineDonationButton("#paypal-button-online-donation-container");
  }
  if ($("#paypal-button-container").length > 0) {
    new PaypalExpressCheckoutButton("#paypal-button-container");
  }
});
