import $ from 'jquery';
import ApplePayHandler from './apple_pay_handler';

// We wait until Apple Pay has been loaded to decide whether or not to
// show payment options or not, since we have to call the Apple Pay
// servers to show/hide the Apple Pay button.
function applePayLoaded() {
  if ($(".btn-payment[data-available='true']").length > 1) {
    $("#payment_options").show();
  } else {
    useCreditCard();
  }
}

function renameFields(newType) {
  var oldType = "credit_card|bank_account|payment_method";
  _.each($("#payment_method_id, #payment_method_token, #account_authorization"), (field) => {
    $(field).prop("name", $(field).prop("name").replace(new RegExp(oldType), newType));
  });
}

function scrollToPaymentInfo() {
  var pos = $('#payment_info').position().top;
  $('body').animate({ scrollTop: pos }, '500', 'swing');
}

function useApplePay() {
  var form = $('.btn-apple-pay').closest("form");
  $('[data-apple-pay-hidden]').hide();
  renameFields("apple_pay");
}

function useBankAccount() {
  $('#credit_card_fields').hide();
  $('#bank_account_fields').show();
  $("#bank_account_holder_type").prop("disabled", false);
  $('[data-apple-pay-hidden]').show();
  renameFields("bank_account");
  scrollToPaymentInfo();
}

function useCreditCard(e) {
  $('#bank_account_fields').hide();
  $("#bank_account_holder_type").prop("disabled", true);

  // Need this extra trigger for some reason, or the bound function
  // in credit_card.js doesn't trigger after the initial page load
  $('#credit_card_fields').show().trigger("show");
  $('[data-apple-pay-hidden]').show();
  renameFields("credit_card");

  if (e !== undefined)
    scrollToPaymentInfo();
}

$(function() {
  if ($("#payment_options").length > 0) {
    $('.btn-apple-pay').click(useApplePay);
    $('.btn-bank-account').click(useBankAccount);
    $('.btn-credit-card').click(useCreditCard);

    new ApplePayHandler($('.btn-apple-pay'), applePayLoaded);
  }
});
