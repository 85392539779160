$(function() {
  if (!$('#donor_payment_method_hidden')) { return; }

  $('#donor_payment_method_hidden').hide();
  $('#show_payment_method').click((e) => {
    e.preventDefault();
    $('#show_payment_method').hide();
    $('#donor_payment_method_hidden').show();

    // Need this extra trigger for some reason, or the bound function
    // in credit_card.js doesn't trigger after the initial page load
    $('#credit_card_fields').show().trigger('show');
  });
});
